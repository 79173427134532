/* eslint-disable eqeqeq */
import React, { Suspense, lazy, useContext } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "reportWebVitals";

import { StateProvider } from "context/store/store";
import ThemeProvider from "context/theme-provider";
import { store } from "context/store/store";

import { AppLayout } from "context/app-context";

import fr from "assets/translations/fr.json";
import en from "assets/translations/en.json";
import { IntlProvider } from "react-intl";

import setupAxios from "http/axios";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";

const LazyRouter = lazy(() => import("./Router"));
const txt = { fr, en };



setupAxios(axios, store);
let currentLang = localStorage.getItem(process.env.REACT_APP_LANG_KEY);

if (!currentLang) {
  const language = navigator.language.split(/[-_]/)[0];
  localStorage.setItem(
    process.env.REACT_APP_LANG_KEY,
    ["fr", "en"].some((lang) => lang == language) ? language : "en"
  );
  currentLang = ["fr", "en"].some((lang) => lang == language) ? language : "en";
}



ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<span>Chargement ...</span>}>
      <IntlProvider locale={currentLang} messages={txt[currentLang]}>
        <StateProvider>
          <AppLayout>
            <ThemeProvider>
              <LazyRouter />
            </ThemeProvider>
          </AppLayout>
        </StateProvider>
      </IntlProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
