 export const pages = [
    {
      pageId: 0,
      pageLibelle: "Tableau de bord",
      pageSlug: "ROLE_DASHBOARD",
      permissions: [
        {
          permissionId: "1",
          permissionName: "Voir",
          permissionSlug: "PERMISSION_VIEW",
        },
      
        {
          permissionId: "2",
          permissionName: "Logs",
          permissionSlug: "PERMISSION_ACTION_VOIR_LOGS",
        },
        
      ],
    },
    {
      pageId: 1,
      pageLibelle: "Gestion des accès",
      pageSlug: "ROLE_ACCES_GRANT",
      permissions: [
        {
          permissionId: "1",
          permissionName: "Créer un rôle",
          permissionSlug: "PERMISSION_CREATE_ROLE",
        },
        {
          permissionId: "3",
          permissionName: "Voir la liste des rôles",
          permissionSlug: "PERMISSION_VIEW_LIST",
        },
        {
          permissionId: "4",
          permissionName: "Modifier un rôle",
          permissionSlug: "PERMISSION_UPD_ROLE",
        },
        {
          permissionId: "5",
          permissionName: "Supprimer un rôle",
          permissionSlug: "PERMISSION_DELETE_ROLE",
        },
        {
          permissionId: "6",
          permissionName: "Ajouter/Retirer des permissions",
          permissionSlug: "PERMISSION_ACTION_DD_REMOVE_PERMISSIONS",
        },
       
      ],
    },
    {
      pageId: 2,
      pageLibelle: "Gestion des utilisateurs",
      pageSlug: "ROLE_USER_MANAGE",
      permissions: [
        {
          permissionId: "1",
          permissionName: "Ajouter un utilisateur",
          permissionSlug: "PERMISSION_CREATE",
        },
        {
          permissionId: "3",
          permissionName: "Liste des utilisateurs",
          permissionSlug: "PERMISSION_VIEW_LIST",
        },
        {
          permissionId: "4",
          permissionName: "Modifier un utilisateur",
          permissionSlug: "PERMISSION_UPD_ROLE",
        },
        {
          permissionId: "5",
          permissionName: "Supprimer un utilisateur",
          permissionSlug: "PERMISSION_DELETE_ROLE",
        },
        {
          permissionId: "6",
          permissionName: "Ajouter/Retirer un rôle",
          permissionSlug: "PERMISSION_ACTION_DD_REMOVE_PERMISSIONS",
        },
       
      ],
    },
    {
      pageId: 3,
      pageLibelle: "Gestion des recettes",
      pageSlug: "ROLE_RECETTE_MANAGE",
      permissions: [
        {
          permissionId: "1",
          permissionName: "Ajouter une recette",
          permissionSlug: "PERMISSION_CREATE",
        },
        {
          permissionId: "3",
          permissionName: "Liste des recettes",
          permissionSlug: "PERMISSION_VIEW_LIST",
        },
        {
          permissionId: "4",
          permissionName: "Modifier une recette",
          permissionSlug: "PERMISSION_UPD",
        },
        {
          permissionId: "5",
          permissionName: "Supprimer une recette",
          permissionSlug: "PERMISSION_DELETE",
        },
       
       
      ],
    },
  ];

  export default pages;