import React, {createContext, useReducer,useEffect} from "react";
import reducer from './reducer'
import _ from 'lodash';

const STORAGE_KEY = process.env.REACT_APP_APP_STORAGE_KEY
const INITIAL_STATE = {
    currentUser: null 
};
const WHITE_LIST = ['lang','currentUser','token']

const store = createContext(INITIAL_STATE);
const { Provider } = store;
 
const StateProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE, (state) => {
        const persistedData = localStorage.getItem(STORAGE_KEY)
        const data = persistedData ? JSON.parse(persistedData) : []
        return { ...state, ...data }
    });

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(_.pick(state,WHITE_LIST)))
    }, [state])

    useEffect(() => {
        window.addEventListener('storage', () => {
            const persistedData = localStorage.getItem(STORAGE_KEY)
            const newData = persistedData ? (JSON.parse(persistedData) ) : null
            if (newData) {
                dispatch({ type: 'SYNC_REQUEST', payload: newData })
            }
        })
    }, []) 

    return (
        <Provider value={{state, dispatch}}>
            {children}
        </Provider>
    )
};

export { store, StateProvider }
export default store;
