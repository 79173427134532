import React from "react";
import {Navbar} from 'components';
import {Container} from 'react-bootstrap';
import styled from 'styled-components';

const DefaultLayout = ({ children }) => {
  return (
    <LayoutContainer fluid className="h-100 w-100 p-0 m-0">
      <Navbar/>
     <main>{children}</main>
    </LayoutContainer> 
  );
};

export default DefaultLayout;

const LayoutContainer = styled(Container)`
  min-height: 100vh;
`;
//background-color: #5F9EA0; for the layout backend for theme changin purpose