import React from "react";

import styled from "styled-components";

function logo({ theme }) {
  const themes = {
    default: { squareBgColor: "#007bff", squareText: "#ffffff", textColor: "#ffffff" },
    primary: { squareBgColor: "#007bff", squareText: "#ffffff", textColor: "#007bff" },
  };
  const selectedTheme = theme ? themes[theme] : themes["default"];
  return (
    <Container>
      <Square theme={selectedTheme}>M</Square>
      <BrandText theme={selectedTheme} className="ml-1">
        Pro Finance
      </BrandText>
    </Container>
  );
}

export default logo;

const Container = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`;

const BrandText = styled.span`
  color: #007bff;
  font-weight: bold;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Square = styled.div`
  height: 40px;
  width: 40px;
  background: ${(props) => props.theme.squareBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.squareText};
`;
