import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import {writtenNumber} from 'configs';

const tdStyle = {
  fontFamily: "Anton",
  fontWeight: "normal",
  fontStyle: "normal",
};

export default class ComponentToPrint extends React.Component {
  render() {
    const { pays } = this.props;

    return (
      <div style={{fontSize:14}} className="m-0 p-0 p-2 w-100">
        <Row className="w-100 p-0 m-0 d-flex pt-2">
          <Col className="text-center" style={{ widtd: "50%" }} xs={5} md={5}>
            <p>{pays}</p>
            <p>*******</p>
            <p className="text-center">
              MINISTERE DU TRAVAIL ET DE LA FONCTION PUBLIQUE
            </p>
            <p>*******</p>
            <p>REGIE PIP</p>
            <p>****</p>
          </Col>
          <Col className="mr-0 pr-0" style={{ widtd: "70%" }} xs={7} md={7}>
            <div className="w-100">
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                OBJET :{" "}
              </span>
              <span className="">
                RECEPTION DE MARCHE RELATIF AUX TRAVAUX D'AMENAGEMENT D'UNE
                SALLE TECHNIQUE A ABOMEY CALAVI POUR LA VALIDATION DES
                CANDIDATURES ET L'ETUDE ELECTRONIQUE DES DOSSIERS
              </span>
              <p className="mt-5 text-center">
                Confère : 0Mn 1753-1760/MTFP/DC/SGM/DAF/PRPM/SA DU 23/09/2020
              </p>
              <p className="mt-2 text-center">
                <span style={{ fontWeight: "bold" }}>REF</span> : DECRET
                N02007-155 DU 03 AVRIL 2007
              </p>
            </div>
          </Col>
        </Row>
        <Row className="w-100 p-0 m-0 d-flex flex-column align-items-center pt-2">
          <p style={{ width: 400 }}>DATE DE DEPART: 09/09/2020</p>
          <p style={{ width: 400 }}>DATE DE D'ARRIVEE: 12/09/2020</p>
        </Row>
        <Row className="p-0 m-0 w-100">
          <table cellspacing="0" className="w-100" border="1">
            <tbody className="w-100">
              <tr>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }} rowspan="2">
                  No D'ORDRE
                </td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 ,minWidth: 200}} rowspan="2" colspan="2" >
                  NOM ET PRENOM
                </td>
                <td style={{ textAlign: "center",paddingLeft: 3,paddingRight: 3}} rowspan="2">
                  FONCTION  /SERVICE
                </td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }} rowspan="2">
                  INDICES
                </td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }} colspan="2">
                  NUITES
                </td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }} colspan="2">
                  REPAS(UN/JR)
                </td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }} colspan="2">
                  REPAS(DEUX/JR)
                </td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }} rowspan="2">
                  TOTAL
                </td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }} rowspan="2" colspan="2">
                  IDENTIFICATION
                </td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }} rowspan="2">
                  EMARGEMENT
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>TAUX/JR</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>NBRE DE JOURS</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>TAUX/JR</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>NBRE DE JOURS</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>TAUX/JR</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>NBRE DE JOURS</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>1</td>
                <td style={{ paddingLeft: 2,paddingRight: 2 }}  colspan="2">CHABI GBESSA Bona G. Assamatou</td>
                <td style={{ textAlign: "center",paddingLeft: 3,paddingRight: 3 }}>DCF</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}> {`>400`}</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>35000</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>1</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>10000</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>1</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>20000</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>-</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>45000</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}  colspan="2"></td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}></td>
              </tr>
              <tr>
                <td style={{paddingLeft: 90}} colspan="11"> TOTAL ..................................................................................................................................................................... </td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}>335000</td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}  colspan="2"></td>
                <td style={{ textAlign: "center",paddingLeft: 5,paddingRight: 5 }}></td>
              </tr>
            </tbody>
          </table>
         <span style={{fontSize:14}}> Arrêté le présent état à la somme de</span>  {writtenNumber(335000)} (335000) francs CFA
        </Row>
        <Row className="p-0 m-0 mt-2 w-100">
          <Col md={6} xs={6}></Col>
          <Col md={6} xs={6}  className="pl-4">Cotonou le </Col>
        </Row>
        <Row style={{justifyContent:'space-between'}} className="p-0 m-0 mt-2 d-flex w-100 px-5">
          <span>LE REGISSEUR PIP</span>
          <span>LE COORDONATEUR</span>
      </Row>
      <Row style={{justifyContent:'space-between'}} className="p-0 m-0 mt-5 d-flex w-100 px-5">
      <span style={{textDecoration:'underline',fontWeight:'bold'}}>Noé B. TCHOUGOU</span>
      <span style={{textDecoration:'underline',fontWeight:'bold'}}>Osséni ABOUMOU</span>
  </Row>
      </div>
    );
  }
}

const TD = styled.td`
  text-align: center;
`;
