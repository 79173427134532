import React from "react";

import { putEntetes, getEntetes } from "http/crud";
import { Form, Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import styled from "styled-components";
import _ from 'lodash';

function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  }
function Entetes(props) {
  const [entete, setEntete] = React.useState({});
  const handleChange = (e) => {
    setEntete({...entete, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    getEnteteData();
  }, []);

  async function getEnteteData() {
    const entete = await getEntetes();
    if (entete.status === 200) {
      setEntete(entete.data);
    }
  }
  const saveEntete = () => {
    putEntetes(_.omit(clean(entete),['updatedAt','createdAt','id']))
      .then(({ data }) => {
          console.log("data upd", data)
        toast.success("Modifications effectuees avec success !");
        getEnteteData();
      })
      .catch((err) => console.log("ERR[GET]ENTETE_", err));
  };

  return (
    <Row className="mt-4">
      <Col md={8} sm={12}>
        <Form.Group>
          <Form.Label>Pays</Form.Label>
          <Form.Control
            name="pays"
            onChange={handleChange}
            type="text"
            value={entete && entete.pays ? entete.pays : ''}
            placeholder=""
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Ministère</Form.Label>
          <Form.Control
            name="ministere"
            onChange={handleChange}
            type="text"
            placeholder=""
            value={entete && entete.ministere ? entete.ministere : ''}
          />
        </Form.Group>
       {/**
       <Form.Group className="mb-3">
          <Form.Label>Cabinet</Form.Label>
          <Form.Control
            name="cabinet"
            onChange={handleChange}
            type="text"
            placeholder=""
            value={entete && entete.cabinet ? entete.cabinet : ''}
          />
        </Form.Group> */}
        <Form.Group className="mb-3">
          <Form.Label>Direction</Form.Label>
          <Form.Control
            name="direction"
            onChange={handleChange}
            type="text"
            placeholder=""
            value={entete && entete.direction ? entete.direction : ''}
          />
        </Form.Group>
        {/**
        <Form.Group className="mb-3">
          <Form.Label>Secretariat</Form.Label>
          <Form.Control
            name="secretariat"
            onChange={handleChange}
            type="text"
            placeholder=""
            value={entete && entete.secretariat ? entete.secretariat : ''}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Regie</Form.Label>
          <Form.Control
            name="regie"
            onChange={handleChange}
            type="text"
            placeholder=""
            value={entete && entete.regie ? entete.regie : ''}
          />
        </Form.Group> */}
        <Button onClick={saveEntete} className="w-100">
          Enregistrer
        </Button>
      </Col>
    </Row>
  );
}

export default Entetes;
