import axios from "axios";
 
//AUTH
export function authSignIn(input) {
	return axios.post(`/api/v1/auth/login`, input);
} 
export function changePassword(input) { 
	return axios.post(`/api/v1/auth/change-password`, input);
}

//ROLES
export function addRole(criteria) {
	return axios.post(``, criteria);
}

export function functionName(criteria) {
	return axios.put(``, criteria);
}

//DEPENSES
export function validateDepense(depense) {
	return axios.get(`/api/v1/depenses/${depense}`);
}
export function deleteDepense(id) {
	return axios.delete(`/api/v1/depenses/${id}`);
}

export function countDepenses() {
	return axios.get(`/api/v1/depenses/count`);
}

//RECETTES
export function addRecettes(criteria) {
	return axios.post(`/api/v1/recettes`, criteria);
}
export function countRecettes() {
	return axios.get(`/api/v1/recettes/count`);
}
export function getRecettes(search="",limit = 10, skip = 0) {
	return axios.get(`/api/v1/recettes?search=${search}&limit=${limit}&skip=${skip}`);
}

export function validaterRecette(recette) {
	return axios.get(`/api/v1/recettes/${recette}`);
}
export function deleteRecette(id) {
	return axios.delete(`/api/v1/recettes/${id}`);
}


//ROLES
export function getRoles() {
	return axios.get(`/api/v1/roles`);
}
export function updRole(id,criteria) {
	return axios.put(`/api/v1/roles/${id}`,criteria);
}
export function removeRole(id) {
	return axios.delete(`/api/v1/roles/${id}`);
}
export function addRoles(criteria) {
	return axios.post(`/api/v1/roles`,criteria);
}

//UTILISATEURS
export function getUtilisateurs(search="",limit = 10, skip = 0) {
	return axios.get(`/api/v1/users?search=${search}&limit=${limit}&skip=${skip}`);
}
export function getAllUtilisateurs(search="") {
	return axios.get(`/api/v1/users/list?search=${search}`);
}
export function addUser(criteria) {
	return axios.post(`/api/v1/users`,criteria);
}
export function updUser(id,criteria) {
	return axios.put(`/api/v1/users/${id}`,criteria);
}
export function removeUser(id) {
	return axios.delete(`/api/v1/users/${id}`);
}

//LIGNES
export function getLignes(search="") {
	return axios.get(`/api/v1/lignes-budgetaire?search=${search}`);
}
export function getAllLignes(search="") {
	return axios.get(`/api/v1/lignes-budgetaire/all?search=${search}`);
}
export function updateLigne(id,criteria) {
	return axios.put(`/api/v1/lignes-budgetaire/${id}`,criteria);
}
export function adffecterLigne(ligne,user) {
	return axios.post(`/api/v1/lignes-budgetaire/${ligne}/user/${user}`);
}
export function addLigne(criteria) {
	return axios.post(`/api/v1/lignes-budgetaire`,criteria);
}

//ACTIVITES
export function addActivite(criteria) {
	return axios.post(`/api/v1/activites`,criteria);
}
export function getActivite() {
	return axios.get(`/api/v1/activites`);
}
export function getAllActivite(search="") {
	return axios.get(`/api/v1/activites/all?search=${search}`);
}
export function updActivite(id,criteria) {
	return axios.put(`/api/v1/activites/${id}`,criteria);
}
export function delActivite(id) {
	return axios.delete(`/api/v1/activites/${id}`);
}

//INDICES
export function addIndice(criteria) {
	return axios.post(`/api/v1/indices`,criteria);
}
export function getIndice() {
	return axios.get(`/api/v1/indices`);
}
export function getAllIndice(search="") {
	return axios.get(`/api/v1/indices/all?search=${search}`);
}
export function updIndice(id,criteria) {
	return axios.put(`/api/v1/indices/${id}`,criteria);
}
export function delIndice(id) {
	return axios.delete(`/api/v1/indices/${id}`);
}

//TYPE DEPENSES
export function getAllTypeDepense() {
	return axios.get(`/api/v1/type-depense/all`);
}
export function addTypeDepense(criteria) {
	return axios.post(`/api/v1/type-depense`,criteria);
}
export function getTypeDepense() {
	return axios.get(`/api/v1/type-depense`);
}
export function updTypeDepense(id,criteria) {
	return axios.put(`/api/v1/type-depense/${id}`,criteria);
}
export function delTypeDepense(id) {
	return axios.delete(`/api/v1/type-depense/${id}`);
}

//DEPENSES
export function addDepense(criteria) {
	return axios.post(`/api/v1/depenses`,criteria);
}
export function getDepenses(search="",limit = 10, skip = 0) {
	return axios.get(`/api/v1/depenses?search=${search}&limit=${limit}&skip=${skip}`);
}
export function getDepensesByActivite(activite) {
	return axios.get(`/api/v1/depenses/activites/${activite}`);
}
export function getDepensesByLigne(ligne) {
	return axios.get(`/api/v1/depenses/lignes/${ligne}`);
}
export function getDepensesCarburant() {
	return axios.get(`/api/v1/depenses/carburants`);
}
export function getDepensesCaisseMenu() {
	return axios.get(`/api/v1/depenses/caissemenu`);
}
export function getDepensesMissionsExt() {
	return axios.get(`/api/v1/depenses/missionsext`);
}
//CONTACTS
export function getAllContacts(search="") {
	return axios.get(`/api/v1/contacts/list?search=${search}`);
}
export function getContacts(search="",limit = 10, skip = 0) {
	return axios.get(`/api/v1/contacts?search=${search}&limit=${limit}&skip=${skip}`);
}
export function addContact(criteria) {
	return axios.post(`/api/v1/contacts`,criteria);
}
export function updContact(id,criteria) {
	return axios.put(`/api/v1/contacts/${id}`,criteria);
}
export function delContact(id) {
	return axios.delete(`/api/v1/contacts/${id}`);
}

//OPERATIONS
export function getOperations() {
	return axios.get(`/api/v1/operations/list`);
}
export function getAllOperations(search="",ligne = "") {
	return axios.get(`/api/v1/operations/all?search=${search}&ligne=${ligne}`);
}

//ETATS
export function getPointCaisse(startDate="",endDate="",start="") {
	return axios.get(`/api/v1/etats/point-caisse?startDate=${startDate}&endDate=${endDate}&start=${start}`);
}
export function getReleveMissions(agent=undefined,vehicule=undefined,depart,arrive) {
	return axios.get(`/api/v1/etats/releve-mission?agent=${agent}&vehicule=${vehicule}&depart=${depart}&arrive=${arrive}`);
}


//ENTETE
export function getEntetes() {
	return axios.get(`/api/v1/entetes`);
}
export function putEntetes(inputs) {
	return axios.put(`/api/v1/entetes`,inputs);
}

//CARBURATION
export function getCarburation() {
	return axios.get(`/api/v1/carburation`);
}
export function putCarburation(inputs) {
	return axios.put(`/api/v1/carburation`,inputs);
}

//VEHICULES
export function getAllVehicules(search="") {
	return axios.get(`/api/v1/vehicules/list?search=${search}`);
}
export function getVehicules(search="",limit = 10, skip = 0) {
	return axios.get(`/api/v1/vehicules?search=${search}&limit=${limit}&skip=${skip}`);
}
export function addVehicule(criteria) { 
	return axios.post(`/api/v1/vehicules`,criteria);
}
export function updVehicule(id,criteria) {
	return axios.put(`/api/v1/vehicules/${id}`,criteria);
}
export function delVehicule(id) {
	return axios.delete(`/api/v1/vehicules/${id}`);
}

//PARCOURS
export function getAllparcours(search="") {
	return axios.get(`/api/v1/parcours/list?search=${search}`);
}
export function getparcours(search="",limit = 10, skip = 0) {
	return axios.get(`/api/v1/parcours?search=${search}&limit=${limit}&skip=${skip}`);
}
export function addparcour(criteria) {
	return axios.post(`/api/v1/parcours`,criteria);
}
export function updparcour(id,criteria) {
	return axios.put(`/api/v1/parcours/${id}`,criteria);
}
export function delparcour(id) {
	return axios.delete(`/api/v1/parcours/${id}`);
}

