import React, { createContext, Component } from "react";

const requiredMsg = { en: "Required field", fr: "Champ requis" };
const stringTypeMsg = {
  en: `Only caracters (A-Z, a-z, symbols -,_)`,
  fr: "Seulement des caractères (A-Z, a-z, symbols -,_)",
};
const integerTypeMsg = {
  en: `Only numeric (0-9)`,
  fr: "Seulement des chiffres (0-9)",
};
const numberTypeMsg = { en: `Only numbers`, fr: "Seulement des nombres" };
const emailTypeMsg = {
  en: `Invalid email address`,
  fr: "Adresse email invalide",
};
const minLengthMsg = {
  en: `At least {{minLength}} caracters`,
  fr: "Au moins {{minLength}} caractères",
};
const maxLengthMsg = {
  en: `At most {{maxLength}} caracters`,
  fr: "Au plus {{maxLength}} caractères",
};
const sizeExacthMsg = {
  en: `Should be {{size}} caracters`,
  fr: "Exactement {{size}} caractères requis",
};

const CURRENTLANG = "fr"

export const resetObject = (obj) => {
  if (typeof obj === "object") {
    let output = {};
    for (const key in obj) {
      output = { ...output, [key]: "" };
    }
    return output;
  } else {
    return obj;
  }
};

const required = (value) => {
  return {
    valid: !!value,
    error: requiredMsg[CURRENTLANG],
  };
};

const string = (value) => {
  if (required(value).valid) {
    return {
      valid: typeof value === "string",
      error: stringTypeMsg[CURRENTLANG],
    };
  } else {
    return {
      valid: false,
      error: required(value).error,
    };
  }
};

const integer = (value) => {
  if (required(value).valid) {
    const reg = /(?<=\s|^)\d+(?=\s|$)/;
    return {
      valid: reg.test(value),
      error: integerTypeMsg[CURRENTLANG],
    };
  } else {
    return {
      valid: false,
      error: required(value).error,
    };
  }
};

const number = (value) => {
  if (required(value).valid) {
    const reg = /[+-]?([0-9]*[.])?[0-9]+/;
    return {
      valid: reg.test(value),
      error: numberTypeMsg[CURRENTLANG],
    };
  } else {
    return {
      valid: false,
      error: required(value).error,
    };
  }
};

const email = (value) => {
  if (string(value).valid) {
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return {
      valid: reg.test(String(value).toLowerCase()),
      error: emailTypeMsg[CURRENTLANG],
    };
  } else {
    return {
      valid: false,
      error: string(value).error,
    };
  }
};

const min = (value, minLength) => {
  if (string(value).valid) {
    return {
      valid: value.length >= minLength,
      error: minLengthMsg[CURRENTLANG].replace("{{minLength}}", minLength),
    };
  } else {
    return {
      valid: false,
      error: string(value).error,
    };
  }
};

const minVal = (value, minValue) => {
  if (number(value).valid) {
    return {
      valid: parseFloat(value) >= minValue,
      error: maxLengthMsg[CURRENTLANG].replace("{{minValue}}", minValue),
    };
  } else {
    return {
      valid: false,
      error: string(value).error,
    };
  }
};

const max = (value, maxLength) => {
  if (string(value).valid) {
    return {
      valid: value.length <= maxLength,
      error: maxLengthMsg[CURRENTLANG].replace("{{maxLength}}", maxLength),
    };
  } else {
    return {
      valid: false,
      error: string(value).error,
    };
  }
};

const maxVal = (value, maxValue) => {
  if (number(value).valid) {
    return {
      valid: parseFloat(value) <= maxValue,
      error: `Doit être inférieur à ${maxValue}`,
    };
  } else {
    return {
      valid: false,
      error: string(value).error,
    };
  }
};

const size = (value, size) => {
  if (string(value).valid) {
    return {
      valid: value.length === size,
      error: sizeExacthMsg[CURRENTLANG].replace("{{size}}", size),
    };
  } else {
    return {
      valid: false,
      error: string(value).error,
    };
  }
};

const date = (value) => {
  if (string(value).valid) {
    const reg = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])/;
    return {
      valid: reg.test(value),
      error: `Date invalide`,
    };
  } else {
    return {
      valid:
        typeof value === "object" &&
        !!value.getTime &&
        value.getTime() === value.getTime(),
      error: `Date invalide`,
    };
  }
};

const phone = (value) => {
  if (string(value).valid) {
    value = String(value);
  }

  const reg = /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g;
  return {
    valid: reg.test(value),
    error: `Téléphone invalide`,
  };
};

export const FormCtx = createContext({ fields: {}, errors: {} });

/*const funcs = {
  required: { 
    rule: () => /\S/,
    formatter(fieldName) {
      return requiredMsg[CURRENTLANG].replace("{{fieldName}}", fieldName);
    },
  },
  numeric: {
    rule: () => /^\d+$/,
    formatter(fieldName) {
      return isNumericMsg[CURRENTLANG].replace("{{fieldName}}", fieldName);
    },
  },
  email: {
    rule: () => /\S+@\S+\.\S+/,
    formatter(fieldName) {
      return isNotValidMsg[CURRENTLANG].replace("{{fieldName}}", fieldName);
    },
  },
};*/

const validations = {
  string,
  email,
  integer,
  number,
  min,
  minVal,
  max,
  maxVal,
  size,
  required,
  date,
  phone,
};
export const validate = (data, rules) => {
  let validationResponse = {};
  let formIsValid = true;

  for (const validation in rules) {
    const key_rules = rules[validation].split("|");
    const key_value = data[validation];
    let isValid = true;
    let errorMessage = "";

    for (let i = 0; i < key_rules.length; i++) {
      const rule = key_rules[i];
      if (rule.includes(":")) {
        const limit = parseInt(rule.split(":")[1]);
        const short_rule = rule.split(":")[0];
        if (!!validations[short_rule]) {
          if (validations[short_rule](key_value, limit).valid) {
            formIsValid = formIsValid && true;
            isValid = isValid && true;
          } else {
            formIsValid = formIsValid && false;
            isValid = isValid && false;
            errorMessage = validations[short_rule](key_value, limit).error;
            break;
          }
        }
      } else {
        if (!!validations[rule]) {
          if (validations[rule](key_value).valid) {
            formIsValid = formIsValid && true;
            isValid = isValid && true;
          } else {
            formIsValid = formIsValid && false;
            isValid = isValid && false;
            errorMessage = validations[rule](key_value).error;
            break;
          }
        }
      }
    }

    validationResponse = {
      ...validationResponse,
      [validation]: {
        valid: isValid,
        error: errorMessage,
      },
    };
  }

  const isFormValid = () => {
    return formIsValid;
  };

  return { response: validationResponse, isFormValid };
};

export default class Form extends Component {
  state = { fields: {}, errors: {} };

  render() {
    const { fields, errors } = this.state;
    const formCtx = {
      fields,
      errors,
      addField: (data) => this.addField(data),
      setFields: this.setFields,
      validateField: this.validateField,
    };

    return (
      <form action="">
        <FormCtx.Provider value={formCtx}>
          {this.props.children}
        </FormCtx.Provider>
      </form>
    );
  }

  setFields = (event, { id }) => {
    //event.persist();
    const { fields } = this.state;
    const field = fields[id];
    this.addField({
      field: {
        ...field,
        value: event.currentTarget.value,
      },
    });
  };

  addField = ({ field }) => {
    const { id } = field;
    field = { value: "", ...field };
    if (id) {
      this.setState((prevState) => {
        return {
          ...prevState,
          fields: {
            ...prevState.fields,
            [id]: field,
          },
        };
      });
      return;
    }
    throw new Error(`ERR_VALIDATION__ Missed id key in field :${field}`);
  };

  validateField = (id) => {
    let isValid = true;
    let errorMessage = "";

    /*console.log("*********************");
    console.log(`${this.state.fields[id]} [validating] ...`,this.state.errors);
    console.log("*********************");*/

    //console.log("this.state.fields[id]", this.state.fields, id)
    const {
      value: fieldValue,
      validate,
      name: displayName,
      customRules = {},
    } = this.state.fields[id];

    const rules = validate ? validate.split("|") : "";

    if (rules.length && rules !== '') {
      for (const rule in rules) {
        const _rule = rules[rule];
        if (_rule.includes(":")) {
          const limit = parseInt(_rule.split(":")[1]);
          const ruleName = _rule.split(":")[0];
          if (!!validations[ruleName]) {
            if (validations[ruleName](fieldValue, limit).valid) {
              isValid = isValid && true;
            } else {
              isValid = isValid && false;
              errorMessage = validations[ruleName](fieldValue, limit).error;
              break;
            }
          }
        } else {
          if (!!validations[_rule]) {
            if (validations[_rule](fieldValue).valid) {
              isValid = isValid && true;
            } else {
              isValid = isValid && false;
              errorMessage = validations[_rule](fieldValue).error;
              break;
            }
          }
        }
      }
      this.setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [id]: errorMessage,
        },
      }));
    }
  };
  
}
