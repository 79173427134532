 export const routes = {
    "ROOT":"/",
    "DASHBOARD":"/tableau-de-bord",
    "ROLE.PERMISSION":"/gestion-acces",
    "UTILISATEUR":"/gestion-utilisateur",
    "RECETTES":"/recettes",
    "DEPENSES":"/depenses", 
    "NOUVELLE.DEPENSES":"/depenses/ajouter",
    "TRANSACTIONS":"/operations", 
    "ETATS":"/etats", 
    "ETATS.MISSIONS":"/etats/missions",
    "ETATS.MISSIONS.SINGLE.EXT":"/etats/missions-single-ext",
    "ETATS.CARBURANT.SINGLE":"/etats/carburation",
    "ETATS.COMM.SINGLE":"/etats/communication",
    "ETATS.POINT.CAISSE":"/etats/point-de-caisse",
    "ETATS.MISSION.EXT":"/etats/missions-ext",
    "ETATS.CAISSE.MENU":"/etats/caisse-menu",
    "ETATS.CARBURANT.MENU":"/etats/point-carburation",
    "ETATS.AUTRES.MENU":"/etats/autres",
    "ETATS.OPERATION.JOURNAL":"/etats/journal",
    "ETATS.MISSIONS.AGENT":"/etats/missions-agent",
    "ETATS.MISSIONS.VEHICULE":"/etats/missions-vehicule",
    "CONTACTS":"/contacts",
    "AUTH.LOGIN": "/auth/login",
    "PARAMETRES":"/parametres",
    "DEPENSES.MISSIONS":"/depenses/missions",
    "DEPENSES.MISSIONS.EXT":"/depenses/missions/exterieure",
    "DEPENSES.CAISSE.MENU":"/depenses/caisee-menu",
    "DEPENSES.FRAIS.CARBURANT":"/depenses/frais-carburant",
    "DEPENSES.FRAIS.COMMUNICATION":"/depenses/frais-communication",
    "DEPENSES.AUTRES":"/depenses/autres"
}
export default routes; 