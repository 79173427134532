import React from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import { useIntl } from "react-intl";


function SearchBar({handleChange}) {
    const intl = useIntl();
  return (
    <Container>
        <CustomInput onChange={handleChange} type="text" placeholder="Rechercher" />
      <SearchIcon />
    </Container>
  );
}

export default SearchBar;

const Container = styled.div`
  width: 100%;
  height: 37px;
  border-radius: 30px;
  border: 1px solid #ddd;;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const CustomInput = styled.input`
    width: 95%;
    padding-left: 5px;
    padding-right: 5px;
    border: none;
    outline: none;
`;


const SearchIcon = styled(FaSearch)`
    position: absolute;
    color: #ddd;
    right: 10px;
`;