import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { writtenNumber } from "configs";
import Armoirie from "assets/images/armoirie.png";
import Colors from "assets/images/colors.png";
import { numberWithCommas } from "helpers";


const tdStyle = {
  fontFamily: "Anton",
  fontWeight: "normal",
  fontStyle: "normal",
}; 

export default class DepenseMissionReport extends React.Component {
  render() {
    const { dataObj, state } = this.props;
    console.log("dataObj", dataObj, state);
    return (
      <div
        style={{ fontSize: 18 }}
        className="d-flex flex-column h-100 p-4 w-100"
      >
        <style type="text/css" media="print">
          {"\
   @page { size: landscape; }\
"}
        </style>
        <div className="w-100">
          <Row className="w-100 p-0 m-0 d-flex pt-2">
            <Col className="m-0 p-0" style={{ width: "50%" }} xs={5} md={5}>
              <div className="d-flex text-left" style={{ opacity: 0.7 }}>
                <img style={{ height: 70 }} alt="" src={Armoirie} />
                <div
                  style={{ alignItems: "left" }}
                  className="ml-2 d-flex flex-column"
                >
                  <span style={{ width: 230, fontSize: 11 }}>
                    {" "}
                    MINISTERE DU TRAVAIL ET DE LA FONCTION PUBLIQUE
                  </span>
                  <div>
                    <img alt="" style={{ width: 200 }} src={Colors} />
                  </div>
                  <span style={{ width: 230, fontSize: 11 }}>
                    {" "}
                    REPUBLIQUE DU BENIN
                  </span>
                </div>
              </div>
            </Col>
            <Col className="mr-0 pr-0" style={{ width: "70%" }} xs={7} md={7}>
              <div className="w-100">
                <span>
                  Cotonou le{" "}
                  {dataObj.date && dataObj.date !== null
                    ? moment(dataObj.date, "x").format("DD/MM/YYYY")
                    : ""}
                </span>
                <p className="mt-2">
                  <span style={{ fontWeight: "bold" }}>REF</span> :{" "}
                  {dataObj && dataObj.ref ? dataObj.ref : ""}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="w-100 p-0 m-0 d-flex flex-column align-items-center mt-4">
            <Col>{state.numero}</Col>
          </Row>
          <Row>
            <Col
              style={{ fontWeight: "bold" }}
              className="w-100 text-center text-uppercase"
            >
              ETAT DE PAIEMENT DES FRAIS DE MISSION A L'EXTERIEUR
            </Col>
          </Row>
          <Row>
            <Col
              style={{ fontWeight: "bold" }}
              className="w-100 mt-3 text-center text-uppercase"
            >
              {state.objet !== null
                ? state.objet
                : dataObj && dataObj.activite
                ? dataObj.activite.libelle
                : ""}
            </Col>
          </Row>
          <Row className="m-0 p-0">
            <Col>{dataObj && dataObj.conf ? dataObj.conf : ""}</Col>
          </Row>
          <Row className="m-0 w-100">
            <table cellspacing="0" className="w-100" border="1">
              <tbody className="w-100">
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    No
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      minWidth: 200,
                    }}
                    colspan="2"
                  >
                    NOM ET PRENOM
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 3,
                      paddingRight: 3,
                      minWidth: 100,
                    }}
                  >
                    TITRE
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      minWidth: 100,
                    }}
                  >
                    MOTIF
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    TAUX NORMAL
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    NB DE JR
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    DELAI DE ROUTE (02)
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    MONTANT
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      minWidth: 100,
                    }}
                  >
                    REF
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      minWidth: 100,
                    }}
                  >
                    EMARGEMENT
                  </td>
                </tr>
                {dataObj &&
                dataObj.ligneMission &&
                Array.isArray(dataObj.ligneMission) &&
                dataObj.ligneMission.length > 0
                  ? dataObj.ligneMission.map((ligneM, k) => {
                      return (
                        <tr key={k}>
                          <td
                          className="py-4"
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {k + 1}
                          </td>
                          <td
                            style={{ paddingLeft: 2, paddingRight: 2 }}
                            colspan="2"
                          >
                            {ligneM && ligneM.contactId
                              ? `${ligneM.contactId.lastname || ""} ${
                                  ligneM.contactId.firstname || ""
                                }`
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            {ligneM &&
                            ligneM.contactId &&
                            ligneM.contactId.fonction !== null
                              ? ligneM.contactId.fonction || ""
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            {dataObj && dataObj.motif
                              ? dataObj.motif || ""
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {ligneM && ligneM.taux ? numberWithCommas(ligneM.taux) || "" : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {ligneM.nbJrN1 !== null ? ligneM.nbJrN1 || "" : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                          {ligneM && ligneM.taux ? numberWithCommas(ligneM.taux) || "" : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {numberWithCommas(parseInt(
                              ligneM.nbJrN1 !== null ? ligneM.nbJrN1 || "" : 1
                            ) * 
                              parseInt(
                                ligneM && ligneM.taux ? ligneM.taux : 0
                              ) +
                              parseInt(
                                ligneM && ligneM.taux ? ligneM.taux : 0
                              ))}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {ligneM && ligneM.ref ? ligneM.ref || "" : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          ></td>
                        </tr>
                      );
                    })
                  : null}
                <tr>
                  <td colSpan="8" className="pl-2">
                    Total
                  </td>
                  <td className="text-center">{numberWithCommas(dataObj.montant)}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </Row>
          <div  style={{fontSize: 18}} className="w-100 mt-2">
            {" "}
            Arrêté le présent état à la somme de{" "}
            {writtenNumber(parseInt(dataObj.montant || 0))} (
            {numberWithCommas(dataObj.montant) || 0}) francs CFA
          </div>
        </div>
        <div className="w-100 mt-auto">
          <Row
            style={{ justifyContent: "space-between" }}
            className="p-0 m-0 mt-4 w-100 px-5"
          >
          <span> {dataObj?.ligne[0]?.titreResponsable || ''}</span>
            <span>{dataObj?.ligne[0]?.titreSignataire || ''}</span>
          </Row>
          <Row
            style={{ justifyContent: "space-between" }}
            className="p-0 m-0 mt-5 d-flex w-100 px-5"
          >
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
              {dataObj &&
              dataObj.ligne &&
              Array.isArray(dataObj.ligne) &&
              dataObj.ligne[0].user
                ? `${dataObj.ligne[0].user.lastname || ""} ${
                    dataObj.ligne[0].user.firstname || ""
                  }`
                : ""}
            </span>
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
            {dataObj?.ligne[0]?.user?.firstname || ''}{' '}
            {dataObj?.ligne[0]?.user?.lastname || ''}
            </span>
          </Row>
        </div>
      </div>
    );
  }
}

const TD = styled.td`
  text-align: center;
`;
