import React from "react";

import styled from "styled-components";
import { GoogleLogin } from "react-google-login";
//import { FaGooglePlusG } from "react-icons/fa";
import GoogleLogo from "assets/images/socials/google.png";
import { useIntl } from "react-intl";

function GoogleLoginBtn(props) {
  const intl = useIntl();
  return (
    <Container>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        // aM8OpWbA0buTL_xFFA3FSuaf => secret
        render={(renderProps) => (
          <Button onClick={renderProps.onClick} disabled={renderProps.disabled}>
            <Logo src={GoogleLogo} alt="" className="mr-3" />
            {intl.formatMessage({ id: "TEXT_LOGIN_WITH_GOOGLE" })}
          </Button>
        )}
        //buttonText="Login with google"
        onSuccess={props.responseGoogle}
        onFailure={props.failureGoogle}
        cookiePolicy={"single_host_origin"}
      />
    </Container>
  );
}

export default GoogleLoginBtn;

const Container = styled.div`
  width: 100%;
  background-color: red;
`;
const Logo = styled.img`
  height: 25px;
  width: 25px;
`;
const Button = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 9px;
  padding-left: 30%;
  margin-top: 13px;
  background-color: rgb(255, 255, 255);
  border: 1px solid transparent;
  box-radius: 1px solid transparent;
  font-weight: 500;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  color: rgba(0, 0, 0, 0.54);
  boxshadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
`;
