import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"; 
import { useIntl } from "react-intl";

const RemotePagination = ({
  columns,
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  bordered,
}) => {
  const intl = useIntl();
  return (
    <div  className="w-100 m-0 p-0">
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div className="w-100 m-0 p-0">
            <BootstrapTable
              remote
              keyField="id"
              data={data}
              columns={columns}
              onTableChange={onTableChange}
              bordered={bordered}
              {...paginationTableProps}
            />
            {
              data.length < 1 && <div className="w-100 text-center"> {intl.formatMessage({ id: "TEXT_NO_DATA" })}</div>
            }
            <div className="d-flex justify-content-center">
              <PaginationListStandalone {...paginationProps} />
            </div>
          </div>
        )}
      </PaginationProvider>
    </div>
  );
}

export default RemotePagination;
