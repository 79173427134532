import React, { useContext } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { themes } from "configs/themes";
import { AppContext } from "../app-context/AppContext";
import FiraCodeRegular from "assets/fonts/Montserrat/Montserrat-Regular.ttf";
import FiraCodeSemiBold from "assets/fonts/Montserrat/Montserrat-SemiBold.ttf";
import FiraCodeBold from "assets/fonts/Montserrat/Montserrat-Bold.ttf";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  @font-face {
    font-family: 'FiraCodeRegular';
    src: local('FiraCodeRegular'), url(${FiraCodeRegular}) format('truetype');
    font-weight: normal;
  }
  @font-face {
    font-family: 'FiraCodeSemiBold';
    src: local('FiraCodeSemiBold'), url(${FiraCodeSemiBold}) format('truetype');
  }
  @font-face {
    font-family: 'FiraCodeBold';
    src: local('FiraCodeRegular'), url(${FiraCodeBold}) format('truetype');
  }
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: FiraCodeRegular, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }
`;

const Provider = ({ children }) => {
  const { currentTheme } = useContext(AppContext);
  return (
    <ThemeProvider theme={themes[currentTheme]}>
      <GlobalStyle/>
      {children}
    </ThemeProvider>
  );
};

export default Provider;
