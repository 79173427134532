import React from "react";
import styled from "styled-components";

function BorderedCard({ children }) {
  return <Container>{children}</Container>;
}

export default BorderedCard;

const Container = styled.div`
  background: #ffffff !important;
  border: 1px solid #e4e4e4 !important;
  font-family: FiraCodeSemiBold, sans-serif;
  border-radius: 4px !important;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 5%) !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px;
  opacity: 0.9;
  color: #484848 !important;
  cursor: ${(props) => (props.pointed ? "pointer" : "normal")};
`;
