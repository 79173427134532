import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { writtenNumber } from "configs";
import Armoirie from "assets/images/armoirie.png";
import Colors from "assets/images/colors.png";
import { numberWithCommas } from "helpers";

const tdStyle = {
  fontFamily: "Anton",
  fontWeight: "normal",
  fontStyle: "normal",
};

export default class DepenseMissionReport extends React.Component {
  render() {
    const { dataObj } = this.props;
    //console.log("number  to letter", writtenNumber(1234));
    return (
      <div
        style={{ fontSize: 18 }}
        className="d-flex flex-column h-100 p-4 w-100"
      >
        <style type="text/css" media="print">
          {
            "\
   @page { size: landscape; }\
"
          }
        </style>
        <div className="w-100">
          <Row className="w-100 p-0 m-0 d-flex pt-2">
            <Col md={4} className="" style={{ widtd: "50%" }}>
              <div className="d-flex text-left" style={{ opacity: 0.7 }}>
                <img style={{ height: 70 }} alt="" src={Armoirie} />
                <div
                  style={{ alignItems: "left" }}
                  className="ml-2 d-flex flex-column"
                >
                  <span style={{ width: 230, fontSize: 11 }}>
                    {" "}
                    MINISTERE DU TRAVAIL ET DE LA FONCTION PUBLIQUE
                  </span>
                  <div>
                    <img alt="" style={{ width: 200 }} src={Colors} />
                  </div>
                  <span style={{ width: 230, fontSize: 11 }}>
                    {" "}
                    REPUBLIQUE DU BENIN
                  </span>
                </div>
              </div>
              <h6 className="mt-3">
                Direction de la Planification de l’Administration et des Finances
              </h6>
              <h6>N° ……………../MTFP/DPAF/SA</h6>
            </Col>

            <Col md={6} className="mr-0 pr-0" style={{ widtd: "70%" }}>
              <div className="w-100">
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  OBJET :{" "}
                </span>
                <span className="">
                  {dataObj && dataObj.activite
                    ? String(dataObj.activite.libelle).toUpperCase()
                    : ""}
                </span>
                <p className="mt-5 text-center">
                  Confère :{" "}
                  {dataObj && dataObj.conf
                    ? String(dataObj.conf).toUpperCase()
                    : ""}
                </p>
                <p className="mt-2 text-center">
                  <span style={{ fontWeight: "bold" }}>REF</span> :{" "}
                  {dataObj && dataObj.ref
                    ? String(dataObj.ref).toUpperCase()
                    : ""}
                </p>
              </div>
            </Col>
            <Col md={2} className="">
              <div className="">
                <h6>01 BP :907 Cotonou Bénin </h6>
                <h6>Tél : +229 21 30 25 70 </h6>
                <h6>travail.infos@gouv.bj</h6>
                <h6>www.travail.gouv.bj </h6>
              </div>
            </Col>
          </Row>
          <Row className="w-100 p-0 m-0 d-flex flex-column align-items-center pt-2">
            <p>
            <strong>DATE DE DEPART:</strong>{" "}
              {dataObj && dataObj.dateDepart
                ? moment(dataObj.dateDepart, "x").format("DD/MM/YYYY")
                : "-"}{" "}  - -
              <strong>DATE DE D'ARRIVEE:</strong>{" "}
              {dataObj && dataObj.dateArrive
                ? moment(dataObj.dateArrive, "x").format("DD/MM/YYYY")
                : "-"}
            </p>
            <p style={{ width: 400 }}></p>
          </Row>
          <Row className="m-0 w-100">
            <table cellspacing="0" className="w-100" border="1">
              <tbody className="w-100">
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                    rowspan="2"
                  >
                    No D'ORDRE
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      minWidth: 200,
                    }}
                    rowspan="2"
                    colspan="2"
                  >
                    NOM ET PRENOM
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 3,
                      paddingRight: 3,
                    }}
                    rowspan="2"
                  >
                    FONCTION /SERVICE
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                    rowspan="2"
                  >
                    INDICES
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                    colspan="2"
                  >
                    NUITES
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                    colspan="2"
                  >
                    REPAS(UN/JR)
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                    colspan="2"
                  >
                    REPAS(DEUX/JR)
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 35,
                      paddingRight: 35,
                    }}
                    rowspan="2"
                  >
                    TOTAL
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                    rowspan="2"
                    colspan="2"
                  >
                    IDENTIFICATION
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                    rowspan="2"
                  >
                    EMARGEMENT
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    TAUX/JR
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    NBRE DE JOURS
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    TAUX/JR
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    NBRE DE JOURS
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    TAUX/JR
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    NBRE DE JOURS
                  </td>
                </tr>
                {dataObj &&
                dataObj.ligneMission &&
                Array.isArray(dataObj.ligneMission) &&
                dataObj.ligneMission.length > 0
                  ? dataObj.ligneMission.map((ligneM, k) => {
                      return (
                        <tr key={k}>
                          <td
                            className="py-4"
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {k + 1}
                          </td>
                          <td
                            style={{ paddingLeft: 2, paddingRight: 2 }}
                            colspan="2"
                          >
                            {ligneM && ligneM.contactId
                              ? `${ligneM.contactId.lastname || ""} ${
                                  ligneM.contactId.firstname || ""
                                }`
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            {ligneM &&
                            ligneM.contactId &&
                            ligneM.contactId.fonction !== null
                              ? ligneM.contactId.fonction || ""
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {ligneM && ligneM.indice
                              ? ligneM.indice.libelle || ""
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {ligneM && ligneM.indice
                              ? numberWithCommas(ligneM.indice.ntTj) || ""
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {ligneM && ligneM.nbJrN1 && ligneM.nbJrN1 !== null
                              ? numberWithCommas(ligneM.nbJrN1) || "-"
                              : "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {ligneM && ligneM.indice
                              ? numberWithCommas(ligneM.indice.rTj1) || ""
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {ligneM && ligneM.nbJrR1 && ligneM.nbJrR1 !== null
                              ? numberWithCommas(ligneM.nbJrR1) || "-"
                              : "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {ligneM && ligneM.indice
                              ? numberWithCommas(ligneM.indice.rTj2) || ""
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {ligneM && ligneM.nbJrR2 && ligneM.nbJrR2 !== null
                              ? numberWithCommas(ligneM.nbJrR2) || "-"
                              : "-"}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {numberWithCommas(
                              (ligneM.nbJrN1 !== null
                                ? parseInt(ligneM.nbJrN1) *
                                  parseInt(ligneM.indice.ntTj)
                                : 0) +
                                (ligneM.nbJrR1 !== null
                                  ? parseInt(ligneM.nbJrR1) *
                                    parseInt(ligneM.indice.rTj1)
                                  : 0) +
                                (ligneM.nbJrR2 !== null
                                  ? parseInt(ligneM.nbJrR2) *
                                    parseInt(ligneM.indice.rTj2)
                                  : 0)
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                            colspan="2"
                          ></td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          ></td>
                        </tr>
                      );
                    })
                  : null}

                <tr>
                  <td className="py-3" style={{ paddingLeft: 90 }} colspan="11">
                    {" "}
                    TOTAL
                    .....................................................................................................................................................................{" "}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    {numberWithCommas(dataObj.montant) || 0}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                    colspan="2"
                  ></td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  ></td>
                </tr>
              </tbody>
            </table>
          </Row>
          <div style={{ fontSize: 18 }} className="mt-2">
            {" "}
            Arrêté le présent état à la somme de{" "}
            {writtenNumber(parseInt(dataObj.montant))} (
            {numberWithCommas(dataObj.montant)}) francs CFA
          </div>
        </div>
        <div className="w-100 mt-auto">
          <Row className="p-0 m-0 mt-2 w-100">
            <Col md={6} xs={6}></Col>
            <Col md={6} xs={6} className="pl-4">
              Cotonou le {moment(dataObj.date, "x").format("DD/MM/YYYY")}
            </Col>
          </Row>
          <Row
            style={{ justifyContent: "space-between" }}
            className="p-0 m-0 mt-2 w-100 px-5"
          >
            <span> {dataObj?.ligne[0]?.titreResponsable || ""}</span>
            <span>{dataObj?.ligne[0]?.titreSignataire || ""}</span>
          </Row>
          <Row
            style={{ justifyContent: "space-between" }}
            className="p-0 m-0 mt-5 d-flex w-100 px-5"
          >
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
              {dataObj &&
              dataObj.ligne &&
              Array.isArray(dataObj.ligne) &&
              dataObj.ligne[0].user
                ? `${dataObj.ligne[0].user.lastname || ""} ${
                    dataObj.ligne[0].user.firstname || ""
                  }`
                : ""}
            </span>
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
              {dataObj &&
              dataObj.ligne &&
              Array.isArray(dataObj.ligne) &&
              dataObj.ligne[0].signataire
                ? `${dataObj.ligne[0].signataire.lastname || ""} ${
                    dataObj.ligne[0].signataire.firstname || ""
                  }`
                : ""}
            </span>
          </Row>
        </div>
      </div>
    );
  }
}

const TD = styled.td`
  text-align: center;
`;
