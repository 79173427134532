/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import { Logo } from "components";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useLocation, useHistory } from "react-router-dom";
import { routesConfigs } from "configs";
import UserPhoto from "assets/images/user.png";
import store from "context/store";
import { AppContext } from "context/app-context/AppContext";

import { LOGOUT_ACTION } from "context/store/actions";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    style={{ textDecoration: "none", color: "white" }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <div>{children}</div>
  </a>
));
let currentLang = localStorage.getItem(process.env.REACT_APP_LANG_KEY);

function NavbarComponent() {
  const location = useLocation();
  const history = useHistory();
  const STORAGE_KEY = process.env.REACT_APP_APP_STORAGE_KEY;
 
  const { dispatch } = React.useContext(store);
  const appContext = React.useContext(AppContext);
  function ifCurrent(url) {
    const isCurrent =
      url !== "/" && location && location.pathname.includes(url)
        ? "true"
        : url === "/" && location && location.pathname === url
        ? "true"
        : "false";
    return isCurrent;
  }
  //console.log("appContext", appContext)
  const logout = () => {
    localStorage.removeItem(STORAGE_KEY);
    dispatch({ type: LOGOUT_ACTION });
    history.push(routesConfigs["AUTH.LOGIN"]);
  };

  const navigateToParametre = () => {
    history.push({ pathname: routesConfigs["PARAMETRES"] });
  };

  const navigateToContactPage = () => {
    history.push({ pathname: routesConfigs["CONTACTS"] });
  };

  return (
    <NavBarContainer bg="light" expand="lg">
      <Navbar.Brand
        className="d-flex align-items-center mr-5"
        href={routesConfigs["ROOT"]}
      >
        <Logo theme="primary" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto h-100 align-items-center">
          <NavLink
            is_current={ifCurrent(routesConfigs["DASHBOARD"])}
            href={routesConfigs["DASHBOARD"]}
          >
            Tableau de bord
          </NavLink>
          {/**
          <NavLink
            is_current={ifCurrent(routesConfigs["ROLE.PERMISSION"])}
            href={routesConfigs["ROLE.PERMISSION"]}
          >
            Gestion d'accès
          </NavLink> */}
          <NavLink
            is_current={ifCurrent(routesConfigs["UTILISATEUR"])}
            href={routesConfigs["UTILISATEUR"]}
          >
            Gestion d'utilisateurs
          </NavLink>
          <NavLink
            is_current={ifCurrent(routesConfigs["RECETTES"])}
            href={routesConfigs["RECETTES"]}
          >
            Recettes
          </NavLink>
          <NavLink
            is_current={ifCurrent(routesConfigs["DEPENSES"])}
            href={routesConfigs["DEPENSES"]}
          >
            Dépenses
          </NavLink>
          <NavLink
            is_current={ifCurrent(routesConfigs["TRANSACTIONS"])}
            href={routesConfigs["TRANSACTIONS"]}
          >
            Opérations
          </NavLink>
          <NavLink
          is_current={ifCurrent(routesConfigs["CONTACTS"])}
          href={routesConfigs["CONTACTS"]}
        >
          Contacts
        </NavLink> 
    
          <NavLink
            is_current={ifCurrent(routesConfigs["ETATS"])}
            href={routesConfigs["ETATS"]}
          >
            États
          </NavLink>
        </Nav>
        <ProfileDropdown>
          <Dropdown.Toggle as={CustomToggle}>
            <Avatar className="ml-2"></Avatar>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ left: -160, top: 50 }}>
            <Dropdown.Item onClick={navigateToParametre} eventKey={2}>
              Paramètres
            </Dropdown.Item>
            <Dropdown.Item onClick={logout} eventKey={2}>
              Se déconnecter
            </Dropdown.Item>
          </Dropdown.Menu>
        </ProfileDropdown>
      </Navbar.Collapse>
    </NavBarContainer>
  );
}

export default NavbarComponent;

const NavBarContainer = styled(Navbar)`
  border-bottom: 1px solid #e4e4e4 !important;
  background: #ffffff !important;
  margin: 0;
  padding: 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  align-items: normal;
  min-height: 70px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const NavLink = styled(Nav.Link)`
  font-family: FiraCodeBold, sans-serif;
  height: 100%;
  display: flex;
  margin-right: 10px;
  align-items: center;
  font-size: 14px;
  word-spacing: 0px !important;
  border-bottom: ${(props) =>
    props.is_current === "true" ? "3px solid #007bff" : ""};
`;

const ProfileDropdown = styled(Dropdown)`
  @media (max-width: 768px) {
    display: none;
  }
`;
const Avatar = styled.div`
  height: 33px;
  width: 33px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${UserPhoto}) no-repeat center;
  background-size: cover;
`;
