import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { writtenNumber } from "configs";
import Armoirie from "assets/images/armoirie.png";
import Colors from "assets/images/colors.png";
import { numberWithCommas } from "helpers";


const tdStyle = {
  fontFamily: "Anton",
  fontWeight: "normal",
  fontStyle: "normal",
};
function sumByKey(arr, key) {
  return arr.reduce((accumulator, current) => accumulator + current[key], 0);
}
export default class DepenseAutresMenuReport extends React.Component {
  render() {
    const { data } = this.props;
    console.log("data", data);
    return (
      <div
        style={{ fontSize: 18 }}
        className="d-flex flex-column align-items-center h-100 p-4 w-100"
      >
        <div className="w-100 d-flex flex-column ">
          <div className="pt-2 w-50 text-center">
            <div className="d-flex text-left" style={{ opacity: 0.7 }}>
              <img style={{ height: 70 }} alt="" src={Armoirie} />
              <div
                style={{ alignItems: "left" }}
                className="ml-2 d-flex flex-column"
              >
                <span style={{ width: 230, fontSize: 11 }}>
                  {" "}
                  MINISTERE DU TRAVAIL ET DE LA FONCTION PUBLIQUE
                </span>
                <div>
                  <img alt="" style={{ width: 200 }} src={Colors} />
                </div>
                <span style={{ width: 230, fontSize: 11 }}>
                  {" "}
                  REPUBLIQUE DU BENIN
                </span>
              </div>
            </div>
          </div>

          <Row className="m-0 w-100 mt-3">
            <div className="w-100">
              <div style={{ fontSize: 18 }}>
                No <span className="ml-4">{data.numero}</span>
              </div>
              <div
                style={{ fontWeight: "bold", fontSize: 16 }}
                className="mb-2"
              >
                {data.objet}
              </div>
            </div>
            <table cellspacing="0" className="w-100" border="1">
              <tbody className="w-100">
                <tr>
                  <td
                  className="py-4"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                      
                    }}
                  >
                    No D'ORDRE
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                    colspan="2"
                  >
                    INTITULES
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    MONTANT
                  </td>
                </tr>
                {data.depenses &&
                Array.isArray(data.depenses) &&
                data.depenses.length > 0
                  ? data.depenses.map((depense, k) => {
                      return (
                        <tr key={k}>
                          <td className="text-center py-4">{k + 1}</td>
                          <td colspan="2" className="pl-2">
                            {depense.ligneMission &&
                            Array.isArray(depense.ligneMission) &&
                            depense.ligneMission.length > 0
                              ? `ETAT DE PAIEMENT a/n ${depense.ligneMission[0].contactId.firstname} ${depense.ligneMission[0].contactId.lastname} et consort`
                              : depense.ligneMission &&
                                Array.isArray(depense.ligneCommunication) &&
                                depense.ligneCommunication.length > 0
                              ? `ETAT DE PAIEMENT a/n ${depense.ligneCommunication[0].nomprenom} et consort`
                              : `Facture N0 ${depense.ref || ""} du ${moment(
                                  depense.date,
                                  "x"
                                ).format("DD/MM/YYYY")}`}
                          </td>
                          <td className="text-center">{depense.montant}</td>
                        </tr>
                      );
                    })
                  : null}
                <tr>
                  <td colspan="3" className="pl-2 py-4">
                    Total
                  </td>
                  <td className="text-center">
                    {numberWithCommas(sumByKey(data.depenses, "montant"))}
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
          <div style={{fontSize: 18}} className="w-100 mt-2">
            {" "}
            Arrêté le présent relevé à la somme de{" "}
            {writtenNumber(sumByKey(data.depenses, "montant"))} (
            {numberWithCommas(sumByKey(data.depenses, "montant"))}) francs CFA
          </div>
        </div>
        <div className="w-100 mt-auto ml-5">
          <div className="mt-4">Cotonou le {moment().format("DD/MM/YYYY")}</div>
          <div className="mt-3">
          {data?.depenses[0]?.ligne[0]?.titreResponsable || ''}
          </div>
          <div style={{ fontWeight: "bold",marginTop: 100 }}>
          {data?.depenses[0]?.ligne[0]?.user?.firstname || ''}{' '}
          {data?.depenses[0]?.ligne[0]?.user?.lastname || ''}
          </div>
        </div>
      </div>
    );
  }
}

const TD = styled.td`
  text-align: center;
`;
