import React from "react";

import styled from 'styled-components';

const FullPageLayout = ({ children }) => {
  return (
    <Container>
       {children}
    </Container>
  );
};

export default FullPageLayout;


const Container = styled.div`
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
`;