import React, { useContext, useEffect } from "react";
import { FormCtx } from "context/form-context/FormContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import startsWith from "lodash.startswith";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CustomPhoneInput = (props) => {
  const { id } = props;
  const { setFields, addField, fields, validateField, errors } = useContext(FormCtx);
  const field = fields[id] || {};
  const {value, onChange, classes = {}} = field;
  const fieldError = errors[id];

  const handleChange = (telephone, country, e, formattedValue) => {

    try {
      setFields({...e,currentTarget:{value: telephone.slice(country.dialCode.length)}}, field);
    } catch (error) {
      throw error;
    }
    if (typeof onChange === "function") {
      onChange({
        telephone: telephone.slice(country.dialCode.length),
        countryCode: country.dialCode,
        nationalite: country.name,
      });
    }
  };


  useEffect(() => {
    if (value !== undefined && value !== '') {
      validateField(id);
    }
  }, [value]);

  useEffect(() => {
    addField({field: props,value:"" });
  }, []);

  return field && field.value !== undefined ? (
    <div>
      <PhoneInput
        isValid={(inputNumber, country, countries) => {
          return countries.some((country) => {
            return (
              startsWith(inputNumber, country.dialCode) ||
              startsWith(country.dialCode, inputNumber)
            );
          });
        }}
        placeholder="Numéro de téléphone"
        autoFormat={false}
        enableSearch={true}
        country="bj"
        inputStyle={{ width: "100%", height: 40 }}
        containerStyle={{border:  fieldError && fieldError !== '' ? '1px solid #FF4500' : ''}}
        onChange={handleChange}
      />
      {fieldError &&  fieldError !== '' &&  <CustomP>{fieldError}</CustomP>}
    </div>
  ) : (
    ""
  );
};

export default CustomPhoneInput;

CustomPhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}
const CustomP = styled.p`
    color: #D8BFD8;
`;