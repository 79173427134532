import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { writtenNumber } from "configs";
import Armoirie from "assets/images/armoirie.png";
import Colors from "assets/images/colors.png";
import { numberWithCommas } from "helpers";

function sumByKey(arr, key) {
  return arr.reduce((accumulator, current) => accumulator + current[key], 0);
}

const tdStyle = {
  fontFamily: "Anton",
  fontWeight: "normal",
  fontStyle: "normal",
};

export default class DepenseCarburantReport extends React.Component {
  render() {
    const { dataObj, state } = this.props;
    console.log("dataObj carb", dataObj, state);
    return (
      <div
        style={{ fontSize: 18 }}
        className="d-flex flex-column h-100 p-4 w-100"
      >
        <style type="text/css" media="print">
          {"\
   @page { size: landscape; }\
"}
        </style>
        <div className="w-100">
          <div style={{ width: "fit-content" }} className="text-center">
            <div className="d-flex text-left" style={{ opacity: 0.7 }}>
              <img style={{ height: 70 }} alt="" src={Armoirie} />
              <div
                style={{ alignItems: "left" }}
                className="ml-2 d-flex flex-column"
              >
                <span style={{ width: 230, fontSize: 11 }}>
                  {" "}
                  {state?.ministere || ""}
                </span>
                <div>
                  <img alt="" style={{ width: 200 }} src={Colors} />
                </div>
                <span style={{ width: 230, fontSize: 11 }}>
                  {" "}
                  {state?.pays || ""}
                </span>
              </div>
            </div>
          </div>
          <div className="w-100 p-0 m-0  mt-4">
            <div style={{ fontSize: 11, fontWeight: "bold" }}>
              {state?.direction || ""}
            </div>
            <div className="mt-2">
              No: <span className="ml-4">{state.numero}</span>
            </div>
          </div>

          <div className="m-0 p-0">
            <div
              style={{ fontWeight: "bold" }}
              className="w-100 mt-3 mb-3 text-uppercase"
            >
              <span className="mr-3"> OBJET:</span>{" "}
              {state.objet !== null
                ? state.objet
                : dataObj && dataObj.activite
                ? dataObj.activite.libelle
                : ""}
            </div>
          </div>
          <Row className="m-0 p-0">
            <Col>{dataObj && dataObj.conf ? dataObj.conf : ""}</Col>
          </Row>
          <Row className="m-0 w-100">
            <table cellspacing="0" className="w-100" border="1">
              <tbody className="w-100">
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    No Ordre
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      minWidth: 200,
                    }}
                    colspan="2"
                  >
                    NOM ET PRENOM
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 3,
                      paddingRight: 3,
                      minWidth: 100,
                    }}
                  >
                    STRUCTURE OU TITRE
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      minWidth: 100,
                    }}
                  >
                    MONTANT/FORFAIT
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                    colspan="2"
                  >
                    IDENTIFICATION
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                    colspan="2"
                  >
                    EMARGEMENT
                  </td>
                </tr>
                {dataObj &&
                dataObj.ligneCarburation &&
                Array.isArray(dataObj.ligneCarburation) &&
                dataObj.ligneCarburation.length > 0
                  ? dataObj.ligneCarburation.map((beneficiaire, k) => {
                      return (
                        <tr key={k}>
                          <td className="text-center py-4">{k + 1}</td>
                          <td className="pl-2" colspan="2">
                            {beneficiaire.contactId &&
                            beneficiaire.contactId !== null
                              ? `${beneficiaire.contactId.lastname} ${beneficiaire.contactId.firstname}`
                              : ""}
                          </td>
                          <td className="text-center">
                            {beneficiaire.organe && beneficiaire.organe !== null
                              ? beneficiaire.organe
                              : ""}
                          </td>
                          <td className="text-center">
                            {beneficiaire.montant &&
                            beneficiaire.montant !== null
                              ? numberWithCommas(beneficiaire.montant)
                              : ""}
                          </td>
                          <td className="text-center" colspan="2">
                            {beneficiaire.identification &&
                            beneficiaire.identification !== null
                              ? beneficiaire.identification
                              : ""}
                          </td>
                          <td colspan="2"></td>
                        </tr>
                      );
                    })
                  : null}
                <tr>
                  <td colspan="4" className="pl-2">
                    Total
                  </td>
                  <td className="text-center">
                    {" "}
                    {numberWithCommas(
                      sumByKey(dataObj.ligneCarburation || [], "montant")
                    )}
                  </td>
                  <td colspan="2"></td>
                  <td colspan="2"></td>
                </tr>
              </tbody>
            </table>
          </Row>
          <div style={{ fontSize: 18 }} className="w-100 mt-2">
            {" "}
            Arrêté le présent état à la somme de{" "}
            {writtenNumber(parseInt(dataObj.montant))} (
            {numberWithCommas(dataObj.montant) || 0}) francs CFA
          </div>
        </div>
        <div className="w-100 mt-auto">
          <div className="w-100 text-center">
            <span>
              Cotonou, le{" "}
              {dataObj.date && dataObj.date !== null
                ? moment(dataObj.date, "x").format("DD/MM/YYYY")
                : ""}
            </span>
          </div>
          <Row
            style={{ justifyContent: "space-between" }}
            className="p-0 m-0 mt-4 w-100 px-5"
          >
            <span> {dataObj?.ligne[0]?.titreResponsable || ""}</span>
            <span>{dataObj?.ligne[0]?.titreSignataire || ""}</span>
          </Row>
          <Row
            style={{ justifyContent: "space-between" }}
            className="p-0 m-0 mt-5 d-flex w-100 px-5"
          >
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
              {`${dataObj?.ligne[0]?.user?.lastname || ""} ${
                dataObj?.ligne[0]?.user?.firstname || ""
              }`}
            </span>
            <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
              {`${dataObj?.ligne[0]?.signataire?.lastname || ""} ${
                dataObj?.ligne[0]?.signataire?.firstname || ""
              }`}
            </span>
          </Row>
        </div>
      </div>
    );
  }
}

const TD = styled.td`
  text-align: center;
`;
