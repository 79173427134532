import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { numberWithCommas } from "helpers";
import { writtenNumber } from "configs";
import Armoirie from "assets/images/armoirie.png";
import Colors from "assets/images/colors.png";

const tdStyle = {
  fontFamily: "Anton",
  fontWeight: "normal",
  fontStyle: "normal",
};
function sumByKey(arr, key) {
  return arr.reduce((accumulator, current) => accumulator + current[key], 0);
}
function solder(array) {
  let soldes = [];
  if (array.length > 0) {
    array.map((obj, i) => {
      if (i == 0) {
        soldes[0] = parseInt(obj.montant);
      } else {
        if (obj.type == "RECETTE") {
          soldes[i] = soldes[i - 1] + parseInt(obj.montant);
        } else {
          soldes[i] = soldes[i - 1] - parseInt(obj.montant);
        }
      }
    });
  }

  //soldes.push();
  return soldes;
}
export default class OperationJournalReport extends React.Component {
  render() {
    const { data } = this.props;
    let soldesArray = solder(data && data.operations ? data.operations : []);
    //console.log("soldesArray", soldesArray,soldesArray[soldesArray.length-1]);
    return (
      <div
        style={{ fontSize: 18 }}
        className="d-flex flex-column align-items-center h-100 p-4 w-100"
      >
        <style type="text/css" media="print">
          {
            "\
      @page { size: landscape; }\
  "
          }
        </style>
        <div className="w-100 d-flex flex-column ">
          <Row className="w-100 p-0 m-0">
            <Col md={6} className="">
              <div className="pt-2 w-50 text-center">
                <div
                  className="d-flex text-left m-0 p-0"
                  style={{ opacity: 0.7 }}
                >
                  <img style={{ height: 70 }} alt="" src={Armoirie} />
                  <div
                    style={{ alignItems: "left" }}
                    className="ml-2 d-flex flex-column"
                  >
                    <span style={{ width: 230, fontSize: 11 }}>
                      {" "}
                      {data?.ministere ||
                        "Ministère du travail et de la fonction publique"}
                    </span>
                    <div>
                      <img alt="" style={{ width: 200 }} src={Colors} />
                    </div>
                    <span style={{ width: 230, fontSize: 11 }}>
                      {" "}
                      {data?.pays || "Bénin"}
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3} className="mr-auto me-auto"></Col>

            <Col md={3} className="mr-auto me-auto">
              <div className="mr-auto me-auto">
                <h6>01 BP :907 Cotonou Bénin </h6>
                <h6>Tél : +229 21 30 25 70 </h6>
                <h6>travail.infos@gouv.bj</h6>
                <h6>www.travail.gouv.bj </h6>
              </div>
            </Col>
          </Row>
          <div className="w-100">
            <div style={{ fontWeight: "bold", textAlign: "center" }}>
              LE LIVRE JOURNAL
            </div>
            {/* <div>{data.pays || ""}</div>
            <div>{data.ministere || ""}</div>
            <div>{data.direction || ""}</div> */}
            <br />
          </div>

          <Row className="m-0 w-100 mt-2">
            <table cellspacing="0" className="w-100" border="1">
              <tbody className="w-100">
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    No
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    NB DE PIECES
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    DATE
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                    colspan="2"
                  >
                    DETAILS DES OPERATIONS
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    RECETTES
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    DEPENSES
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    SOLDE
                  </td>
                </tr>
                {data.operations &&
                Array.isArray(data.operations) &&
                data.operations.length > 0 ? (
                  data.operations.map((operation, k) => {
                    //console.log("operation",operation)
                    return (
                      <tr key={k}>
                        <td className="text-center py-4">{k + 1}</td>
                        <td></td>
                        <td className="text-center">
                          {moment(operation.date, "x").format("DD/MM/YYYY")}
                        </td>
                        <td colspan="2" className="px-1">
                          {operation.type == "RECETTE"
                            ? `Ordre ou Mandat de paiement N° ${
                                operation.ref
                              } du ${
                                operation.date && operation.date !== null
                                  ? moment(operation.date, "x").format(
                                      "DD/MM/YYYY"
                                    )
                                  : moment(operation.createdAt, "x").format(
                                      "DD/MM/YYYY"
                                    )
                              }`
                            : operation.depense !== "null" &&
                              operation.depense !== null &&
                              operation.depense.ligneMission &&
                              Array.isArray(operation.depense.ligneMission) &&
                              operation.depense.ligneMission.length > 0
                            ? `ETAT DE PAIEMENT a/n ${operation.depense.ligneMission[0].contactId.firstname} ${operation.depense.ligneMission[0].contactId.lastname} et consort`
                            : operation.depense !== null &&
                              operation.depense.ligneMission &&
                              Array.isArray(
                                operation.depense.ligneCommunication
                              ) &&
                              operation.depense.ligneCommunication.length > 0
                            ? `ETAT DE PAIEMENT a/n ${operation.depense.ligneCommunication[0].nomprenom} et consort`
                            : `Facture N° ${operation.depense.ref || ""} du ${
                                operation.depense.date &&
                                operation.depense.date !== null
                                  ? moment(operation.depense.date, "x").format(
                                      "DD/MM/YYYY"
                                    )
                                  : moment(
                                      operation.depense.createdAt,
                                      "x"
                                    ).format("DD/MM/YYYY")
                              }`}
                        </td>
                        <td className="text-center">
                          {operation.type == "RECETTE"
                            ? numberWithCommas(operation.montant)
                            : "-"}
                        </td>
                        <td className="text-center">
                          {operation.type == "DEPENSE"
                            ? numberWithCommas(operation.montant)
                            : "-"}
                        </td>
                        <td className="text-center">
                          {numberWithCommas(soldesArray[k])}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <p>Aucune operation !</p>
                )}
                <tr>
                  <td colspan="5" className="pl-2 text-center">
                    Total
                  </td>
                  <td className="text-center">
                    {" "}
                    {numberWithCommas(
                      sumByKey(
                        data && data.operations
                          ? data.operations.filter((x) => x.type == "RECETTE")
                          : [],
                        "montant"
                      )
                    )}
                  </td>
                  <td className="text-center">
                    {numberWithCommas(
                      sumByKey(
                        data && data.operations
                          ? data.operations.filter((x) => x.type == "DEPENSE")
                          : [],
                        "montant"
                      )
                    )}
                  </td>
                  <td className="text-center">
                    {numberWithCommas(soldesArray[soldesArray.length - 1] || 0)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
        </div>

        <div className="w-100 mt-3">
          Arrêter la présente page du livre journal en recette pour un montant
          total de{" "}
          {writtenNumber(
            sumByKey(
              data && data.operations
                ? data.operations.filter((x) => x.type == "RECETTE")
                : [],
              "montant"
            ) || 0
          )}{" "}
          (
          {numberWithCommas(
            sumByKey(
              data && data.operations
                ? data.operations.filter((x) => x.type == "RECETTE")
                : [],
              "montant"
            )
          )}
          ) francs cfa, en dépense pour un montant total de{" "}
          {writtenNumber(
            sumByKey(
              data && data.operations
                ? data.operations.filter((x) => x.type == "DEPENSE")
                : [],
              "montant"
            ) || 0
          )}{" "}
          (
          {numberWithCommas(
            sumByKey(
              data && data.operations
                ? data.operations.filter((x) => x.type == "DEPENSE")
                : [],
              "montant"
            )
          )}
          ) francs cfa et un solde total de{" "}
          {writtenNumber(soldesArray[soldesArray.length - 1] || 0)} (
          {numberWithCommas(soldesArray[soldesArray.length - 1] || 0)}) francs
          cfa.
          {/** 
          <div className="mt-4">Cotonou le {moment().format("DD/MM/YYYY")}</div>
          <div className="mt-3">LE Régisseur </div>
          <div style={{ fontWeight: "bold" }} className="mt-5">
            {data.currentUser !== null ? data.currentUser.fullname : ""}
          </div> */}
        </div>
        <Row className="w-100 mt-5 m-0">
          <Col md={6} className="">
            <p>  <strong> RC/CSAF </strong></p>
          </Col>

          <Col md={3} className=""></Col>

          <Col md={3} className="">
            <div className="">
              <p className="text-center"><strong> Cotonou, le {moment().format("DD/MM/YYYY")} </strong></p>
              <p className="text-center"><strong> DDTFP </strong></p>
              
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const TD = styled.td`
  text-align: center;
`;
