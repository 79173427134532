import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { writtenNumber } from "configs";
import { numberWithCommas } from "helpers";
import Armoirie from "assets/images/armoirie.png";
import Colors from "assets/images/colors.png";

const tdStyle = {
  fontFamily: "Anton",
  fontWeight: "normal",
  fontStyle: "normal",
};
function sumByKey(arr, key) {
  return arr.reduce((accumulator, current) => accumulator + current[key], 0);
}

export default class DepenseCarburantMenuReport extends React.Component {
  render() {
    const { data } = this.props;
    console.log("data", data);
    return (
      <div
        style={{ fontSize: 18 }}
        className="d-flex flex-column align-items-center h-100 p-4 w-100"
      >
        <div className="w-100 d-flex flex-column ">
          <Row className="w-100 p-0 m-0">
            <Col md={6} className="">
              <div className="pt-2 w-50 text-center">
                <div
                  className="d-flex text-left m-0 p-0"
                  style={{ opacity: 0.7 }}
                >
                  <img style={{ height: 70 }} alt="" src={Armoirie} />
                  <div
                    style={{ alignItems: "left" }}
                    className="ml-2 d-flex flex-column"
                  >
                    <span style={{ width: 230, fontSize: 11 }}>
                      {" "}
                      {data?.ministere ||
                        "Ministère du travail et de la fonction publique"}
                    </span>
                    <div>
                      <img alt="" style={{ width: 200 }} src={Colors} />
                    </div>
                    <span style={{ width: 230, fontSize: 11 }}>
                      {" "}
                      {data?.pays || "Bénin"}
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3} className=""></Col>

            <Col md={3} className="">
              <div className="">
                <h6>01 BP :907 Cotonou Bénin </h6>
                <h6>Tél : +229 21 30 25 70 </h6>
                <h6>travail.infos@gouv.bj</h6>
                <h6>www.travail.gouv.bj </h6>
              </div>
            </Col>
          </Row>

          <Row className="w-100 mt-3 mb-5">
            <div className="w-100">
              <div style={{ fontSize: 18 }}>
                No{" "}
                <span className="ml-4">{data.numero ? data.numero : ""}</span>
              </div>
              <div
                style={{ fontWeight: "bold", fontSize: 16 }}
                className="mb-2"
              >
                {data.objet ? data.objet : ""}
              </div>
            </div>
            <table cellspacing="0" className="w-100" border="1">
              <tbody className="w-100">
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    No
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                    colspan="2"
                  >
                    INTITULES
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    MONTANT
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    SOLDE
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    OBSERVATIONS
                  </td>
                </tr>
                {data.depenses &&
                Array.isArray(data.depenses) &&
                data.depenses.length > 0
                  ? data.depenses.map((depense, k) => {
                      return (
                        <tr key={k}>
                          <td className="text-center py-4">{k + 1}</td>
                          <td colspan="2" className="pl-2">
                            {depense.ligneMission &&
                            Array.isArray(depense.ligneMission) &&
                            depense.ligneMission.length > 0
                              ? `ETAT DE PAIEMENT no ${k + 1} a/n ${
                                  depense.ligneMission[0].contactId.firstname
                                } ${
                                  depense.ligneMission[0].contactId.lastname
                                } et consort`
                              : `Ordre de sortie no ${k + 1} du ${moment(
                                  depense.date,
                                  "x"
                                ).format("DD/MM/YYYY")}`}
                          </td>
                          <td className="text-center">
                            {numberWithCommas(depense.montant)}
                          </td>
                          <td className="text-center">3 855 000</td>
                          <td></td>
                        </tr>
                      );
                    })
                  : null}
                <tr>
                  <td colspan="3" className="pl-2">
                    Total
                  </td>
                  <td className="text-center">
                    {" "}
                    {numberWithCommas(sumByKey(data.depenses, "montant"))}
                  </td>
                  <td className="text-center">
                    {numberWithCommas(sumByKey(data.depenses, "montant"))}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </Row>
          <div style={{ fontSize: 18 }} className="w-100 mt-2">
            {" "}
            Arrêté le présent relevé à la somme de{" "}
            {writtenNumber(sumByKey(data.depenses, "montant"))} (
            {numberWithCommas(sumByKey(data.depenses, "montant"))}) francs CFA
          </div>
        </div>
        <div className="w-100 mt-auto ml-5">
          <div className="mt-4">Cotonou le {moment().format("DD/MM/YYYY")}</div>
          <div className="mt-3">
            {data?.depenses[0]?.ligne[0]?.titreResponsable || ""}
          </div>
          <div style={{ fontWeight: "bold", marginTop: 100 }}>
            {data?.depenses[0]?.ligne[0]?.user?.firstname || ""}{" "}
            {data?.depenses[0]?.ligne[0]?.user?.lastname || ""}
          </div>
        </div>
      </div>
    );
  }
}

const TD = styled.td`
  text-align: center;
`;
