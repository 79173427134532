import React from "react";

import styled from "styled-components";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
//import { FaFacebookF } from "react-icons/fa";
import FacebookLogo from "assets/images/socials/facebook.png";
import { useIntl } from "react-intl";

function FacebookLoginBtn(props) {
    const intl = useIntl();
  return (
    <Container>
      <FacebookLogin
        style={{
          maxHeight: "20px !important",
          width: "100%",
          backgroundColor: "red",
        }}
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email,picture"
        callback={props.responseFacebook}
        //icon="fa-facebook"
        render={(renderProps) => (
          <Button onClick={renderProps.onClick}>
            <Logo src={FacebookLogo} alt="" className="mr-3" />  {intl.formatMessage({ id: "TEXT_LOGIN_WITH_FB" })}
          </Button>
        )}
      />
    </Container>
  );
}

export default FacebookLoginBtn;

const Container = styled.div`
  width: 100%;
  background-color: blue;
`;

const Button = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 9px;
  padding-left: 30%;
  margin-top: 13px;
  background-color: rgb(255, 255, 255);
  border: 1px solid transparent;
  box-radius: 1px solid transparent;
  font-weight: 500;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  color: rgba(0, 0, 0, 0.54);
  boxshadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
`;

const Logo = styled.img`
  height: 25px;
  width: 25px;
`;
