import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { writtenNumber } from "configs";
import { numberWithCommas } from "helpers";
import Armoirie from "assets/images/armoirie.png";
import Colors from "assets/images/colors.png";
const tdStyle = {
  fontFamily: "Anton",
  fontWeight: "normal",
  fontStyle: "normal",
};

function sumByKey(arr, key) {
  return arr.reduce((accumulator, current) => accumulator + current[key], 0);
}

export default class PointDeCaisseReport extends React.Component {
  render() {
    const { data } = this.props;
    console.log("props data", data, Object.keys(data));
    return (
      <div
        style={{ fontSize: 18 }}
        className="d-flex flex-column h-100 p-4 w-100"
      >
        <style type="text/css" media="print">
          {
            "\
              @page { size: landscape; }\
            "
          }
        </style>

        <div className="w-100 d-flex flex-column">
          <Row className="w-100 p-0 m-0">
            <Col md={6} className="">
              <div className="pt-2 w-50 text-center">
                <div
                  className="d-flex text-left m-0 p-0"
                  style={{ opacity: 0.7 }}
                >
                  <img style={{ height: 70 }} alt="" src={Armoirie} />
                  <div
                    style={{ alignItems: "left" }}
                    className="ml-2 d-flex flex-column"
                  >
                    <span style={{ width: 230, fontSize: 11 }}>
                      {" "}
                      {data?.ministere ||
                        "Ministère du travail et de la fonction publique"}
                    </span>
                    <div>
                      <img alt="" style={{ width: 200 }} src={Colors} />
                    </div>
                    <span style={{ width: 230, fontSize: 11 }}>
                      {" "}
                      {data?.pays || "Bénin"}
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3} className="">
            </Col>

            <Col md={3} className="">
              <div className="">
                <h6>01 BP :907 Cotonou Bénin </h6>
                <h6>Tél : +229 21 30 25 70 </h6>
                <h6>travail.infos@gouv.bj</h6>
                <h6>www.travail.gouv.bj </h6>
              </div>
            </Col>
          </Row>

          <Row className="w-100 p-0 m-0 d-flex flex-column align-items-center pt-2">
            <Col className="">
              <p>
                Direction de la Planification de l’Administration et des Finances
              </p>
              <p>N° ……………../MTFP/DPAF/SA</p>
            </Col>
          </Row>
          <Row className="w-100 p-0 m-0 d-flex flex-column align-items-center pt-2">
            <p>POINT DE LA CAISSE CE {moment().format("DD/MM/YYYY")} </p>
          </Row>
          <Row className="m-0 w-100">
            <table cellspacing="0" className="w-100" border="1">
              <tbody className="w-100">
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                    //rowspan="2"
                    colspan="2"
                  >
                    RUBRIQUES
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      minWidth: 200,
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                    //rowspan="2"
                    //colspan="2"
                  >
                    MONTANT ENCAISSE
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 3,
                      paddingRight: 3,
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                    //rowspan="2"
                  >
                    MONTANT DECAISSE
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                    //rowspan="2"
                  >
                    SOLDE
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                    //colspan="2"
                    colspan="2"
                  >
                    OBSERVATIONS
                  </td>
                </tr>

                {data &&
                typeof data == "object" &&
                Array.isArray(Object.keys(data)) &&
                Object.keys(data).length > 0
                  ? Object.keys(data).map((ligne, k) => {
                      return (
                        <tr key={k}>
                          <td
                            style={{
                              paddingLeft: 2,
                              paddingRight: 2,
                              paddingTop: 15,
                              paddingBottom: 15,
                            }}
                            colspan="2"
                          >
                            {data[ligne].libelle
                              ? data[ligne].libelle.toUpperCase()
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                            {data[ligne].recettes &&
                            Array.isArray(data[ligne].recettes) &&
                            data[ligne].recettes.length > 0
                              ? numberWithCommas(
                                  sumByKey(data[ligne].recettes, "montant")
                                )
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {data[ligne].depenses &&
                            Array.isArray(data[ligne].depenses) &&
                            data[ligne].depenses.length > 0
                              ? numberWithCommas(
                                  sumByKey(data[ligne].depenses, "montant")
                                )
                              : ""}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {(data[ligne].recettes &&
                            Array.isArray(data[ligne].recettes) &&
                            data[ligne].recettes.length > 0
                              ? sumByKey(data[ligne].recettes, "montant")
                              : 0) -
                              (data[ligne].depenses &&
                              Array.isArray(data[ligne].depenses) &&
                              data[ligne].depenses.length > 0
                                ? sumByKey(data[ligne].depenses, "montant")
                                : 0) >
                            0
                              ? numberWithCommas(
                                  (data[ligne].recettes &&
                                  Array.isArray(data[ligne].recettes) &&
                                  data[ligne].recettes.length > 0
                                    ? sumByKey(data[ligne].recettes, "montant")
                                    : 0) -
                                    (data[ligne].depenses &&
                                    Array.isArray(data[ligne].depenses) &&
                                    data[ligne].depenses.length > 0
                                      ? sumByKey(
                                          data[ligne].depenses,
                                          "montant"
                                        )
                                      : 0)
                                )
                              : "-"}
                          </td>
                          <td
                            colspan="2"
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {""}
                          </td>
                        </tr>
                      );
                    })
                  : null}

                <tr>
                  <td
                    colspan="2"
                    style={{
                      paddingLeft: 3,
                      paddingRight: 3,
                      fontWeight: "bold",
                      paddingTop: 15,
                      paddingBottom: 15,
                    }}
                  >
                    SOLDE EN CAISSE
                  </td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    {numberWithCommas(
                      sumByKey(
                        Object.keys(data).map((x) => data[x]),
                        "solde"
                      )
                    )}
                  </td>
                  <td colspan="2"></td>
                </tr>
              </tbody>
            </table>
            <div className="mt-2" style={{ fontWeight: "bold", fontSize: 18 }}>
              {" "}
              Arrêté la présente situation de caisse à la somme de{" "}
              {writtenNumber(
                sumByKey(
                  Object.keys(data).map((x) => data[x]),
                  "solde"
                ) || 0
              )}{" "}
              (
              {numberWithCommas(
                sumByKey(
                  Object.keys(data).map((x) => data[x]),
                  "solde"
                )
              )}
              ) francs CFA
            </div>
          </Row>
        </div>

        <div
          style={{ justifyContent: "space-between" }}
          className="p-0 m-0 mt-5 d-flex flex-column w-100"
        >
          <span>
            {data &&
              typeof data == "object" &&
              Array.isArray(Object.keys(data)) &&
              Object.keys(data).length > 0 &&
              data[0]?.titreSignataire}
          </span>
          <span style={{ fontWeight: "bold", marginTop: 100 }}>
            {data[0]?.signataire?.firstname || ""}{" "}
            {data[0]?.signataire?.lastname || ""}
          </span>
        </div>
      </div>
    );
  }
}

const TD = styled.td`
  text-align: center;
`;
