import { LOGOUT } from "redux/actions/auth/types";
const STORAGE_KEY = process.env.REACT_APP_APP_STORAGE_KEY;

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const persistedData = localStorage.getItem(STORAGE_KEY);
      const data = persistedData ? JSON.parse(persistedData) : []
      config.baseURL = "https://mprofinancesmtfp.gouv.bj/backend" || "";
      config.headers.post["Content-Type"] = "application/json";
      if (data.currentUser && data.currentUser.tokenSalt !== undefined) {
        config.headers.Authorization = `Bearer ${data.currentUser.tokenSalt}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (401 === error.response.status || 498 === error.response.status) {
        localStorage.removeItem(STORAGE_KEY);
        window.location.reload()
      } else {
        return Promise.reject(error);
      }
    }
  );
}
