import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { useIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from 'prop-types';

function ButtonSubmit(props) {
  const intl = useIntl();
  return (
    <Container onClick={props.onClick} className="mt-4">
      {props.children || intl.formatMessage({ id: "TEXT_SUBMIT" })}
      {props.isLoading && <Spinner className="ml-2" animation="border" style={{color:'white'}} />}
    </Container>
  );
}

export default ButtonSubmit;

ButtonSubmit.propTypes = {
  onClick: PropTypes.func,
  isLoading: PropTypes.bool
}

const Container = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color:#4169E1;
  border: none;
  outline: none;
  &:focus {
    background-color: #4169E1;
  };
  &:active {
    background-color: #4169E1;
  };
  &:hover {
    background-color: #4169E1;
  }
`;
