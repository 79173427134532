import React, { useContext, useEffect } from "react";
import { FormCtx } from "context/form-context/FormContext";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FaEye ,FaEyeSlash} from 'react-icons/fa';

const CustomInput = (props) => {
  const { id, type,onChange,name,...others } = props;
  const { fields, setFields, addField, errors, validateField } =
    useContext(FormCtx);
  const [inputType, setInputType] = React.useState(type || "text");

  const field = fields[id] || {};
  const fieldError = errors[id] || "";
  const { value = "" } = field;

  useEffect(() => {
    addField({ field: props, value: "" });
  }, []);

  useEffect(() => {
    if (field.value !== undefined) {
      validateField(id);
    }
  }, [value]);

  return field ? (
    <div>
      <InputContainer hasError={fieldError && fieldError !== ""}>
        <Input
          type={inputType}
          value={field && value}
          hasError={fieldError && fieldError !== ""}
          name={name}
          id={id}
          {...others}
          onChange={(event) => {
            setFields(event, field)
            onChange(event)
          }}
        />
        {props.hasEye && (inputType === 'password' ? <FaEye onClick={()=> setInputType('text')} style={{cursor:'pointer'}} /> : <FaEyeSlash onClick={()=> setInputType('password')} style={{cursor:'pointer'}} />)}
      </InputContainer>

      {fieldError && fieldError !== "" && <CustomP>{fieldError}</CustomP>}
    </div>
  ) : (
    ""
  );
};

export default CustomInput;

CustomInput.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  validate: PropTypes.string,
  hasEye: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired
};

const Input = styled.input`
  border: none;
  width: 95%;
  &:focus {
    border: none;
    outline: none;
  }
  &:active {
    border: none;
    outline: none;
  }
`;
const InputContainer = styled.div`
  overflow: visible;
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: ${(props) =>
    props.hasError ? "1px solid #FF4500" : "1px solid #ced4da"};
`;
const CustomP = styled.p`
  color: #DC143C;
`;
