import {LOGIN_ACTION,LOGOUT_ACTION} from '../actions';

const Reducer = (state, action) => {
    switch (action.type) {
        case LOGIN_ACTION :
            return {...state, currentUser: action.payload};
        case 'SYNC_REQUEST':
            return { ...state, currentUser: action.payload };
            case LOGOUT_ACTION :
                return {currentUser: null};
        default:
            return state;
    }
};

export default Reducer;
