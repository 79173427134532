import React, { useState, useEffect } from "react";
import themeConfig, { themes } from "configs/themes";
import DefaultLayout from "layouts/DefaultLayout";
import FullLayout from "layouts/FullLayout";

const AppContext = React.createContext();

const layouts = {
  default: DefaultLayout,
  full: FullLayout,
};
 
function AppLayout({ children }) {
  const [state, setState] = useState({
    theme: themes[themeConfig.theme],
    currentTheme: themeConfig.theme,
    activeLayout: "default",
    currentLang: themeConfig.lang
  });

  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  // Breakpoints
  const isMobile = width > 1023;
  const isTablet = width < 767 && width > 400;
  const isDesktop = width > 1023;

  return (
    <AppContext.Provider
      value={{
        ...state,
        isMobile,isTablet,isDesktop,
        FullLayout: layouts["full"],
        DefaultLayout: layouts["default"],
        switchLayout: (layout) => setState({ activeLayout: layout }),
        changeLang: (lang) => {
          localStorage.setItem(process.env.REACT_APP_LANG_KEY, lang);
          setState({ ...state,  currentLang: lang })
          window.location.reload();
        },
        switchTheme: (theme) => setState({ ...state, currentTheme: theme,theme: themes[theme] || {}, }),
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppLayout, AppContext };
export default AppLayout;
