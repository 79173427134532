import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { writtenNumber } from "configs";
import Armoirie from "assets/images/armoirie.png";
import Colors from "assets/images/colors.png";
import { numberWithCommas } from "helpers";

const tdStyle = {
  fontFamily: "Anton",
  fontWeight: "normal",
  fontStyle: "normal",
};

export default class DepenseMissionVehiculeReport extends React.Component {
  constructor(props){
    super(props);

  }
  render() {
    const { dataObj } = this.props;
    //console.log("number  to letter", writtenNumber(1234));
    console.log("data=======",dataObj)
    return (
      <div
        style={{ fontSize: 18 }}
        className="d-flex flex-column h-100 p-4 w-100"
      >
        <style type="text/css" media="print">
          {"\
   @page { size: landscape; }\
"}
        </style>
        <div className="m-0 w-100">
          <div
            style={{ width: "100%", justifyContent: "center", display: "flex" }}
            className="text-center w-100"
          >
            ETAT RECAPITULATIF DES FRAIS DE MISSION ET DE CARBURANT DU MOIS DE{" "}
          </div>
          <Row className="m-0 w-100 mt-2">
            <table cellspacing="0" className="w-100" border="1">
              <tbody className="w-100">
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      fontWeight:"bold",
                      fontSize: 12,
                      minWidth: 100,
                    }}
                    colspan="2"
                  >
                    DATES
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      minWidth: 200,
                      fontWeight:"bold",
                      fontSize: 12
                    }}
                    colspan="2"
                  >
                    REFERENCE DE L'ORDRE DE MISSION
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 3,
                      paddingRight: 3,
                      minWidth: 100,
                      fontWeight:"bold",
                      fontSize: 12
                    }}
                  >
                    MONTANT FRAIS DE MISSION
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      minWidth: 100,
                      fontWeight:"bold",
                      fontSize: 12
                    }}
                  >
                    MONTANT CARBURANT
                  </td>
                </tr>
                {dataObj && dataObj.data && Array.isArray( dataObj.data) &&  dataObj.data.length > 0
                  ?  dataObj.data.map((item, k) => {
                      return (
                        <tr key={k}>
                          <td
                            className="py-4"
                            style={{
                              textAlign: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                            colspan="2"
                          >
                          {item.date && item.date !== null
                            ? moment(item.date, "x").format("DD/MM/YYYY")
                            : ""}
                          </td>
                          <td
                            style={{ paddingLeft: 2, paddingRight: 2 }}
                            colspan="2"
                          >
                           {item?.ref}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                           {/*numberWithCommas(item?.montant || 0) || ""*/}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              paddingLeft: 3,
                              paddingRight: 3,
                            }}
                          >
                           {numberWithCommas(item?.coutTotalCarburation || 0) || ""}
                          </td>
                     
                      
                        </tr>
                      );
                    })
                  : null}
                
              </tbody>
            </table>
          </Row>
        </div>
      </div>
    );
  }
}

const TD = styled.td`
  text-align: center;
`;
